<template>
  <div data-app>
    <div class="row mx-1">
      <p></p>
      <div class="col-md-12 row m-0 bg-white p-0">
        <div class="py-4 px-4 border_b_silver col-6">
          <span class="card-label font-weight-bolder Main_Blue"
            >CV Search
          </span>
        </div>
        <div class="py-4 px-4 border_b_silver col-6 row m-0 cv_search_slect_ch">
          <div class="col-md-8 py-0 pr-4">
            <div
              class="d-flex"
              v-if="
                city !== null ||
                desired_salary !== null ||
                job_function !== null ||
                job_shift !== null ||
                career_level !== null
              "
            >
              <v-text-field
                v-model="search_title"
                label="Search title"
                required
              ></v-text-field>

              <v-btn
                class="btn btn-sm font-weight-bold py-2 px-3 px-xxl-5 rounded-0"
                small
                style="float: right"
                :disabled="search_title !== '' ? false : true"
                @click="saveSearch"
              >
                Save search
              </v-btn>
            </div>
          </div>
          <div class="col-md-4 p-0">
            <b-form-select
              :v-model="saved_search_title"
              :options="saved_search_titles"
              size="sm"
              class="rounded-0"
              style="float: right"
              @change="
                (e) => {
                  myFunc1(e);
                }
              "
            ></b-form-select>
          </div>
        </div>

        <v-card class="rounded-0">
          <div class="d-none" style="display: flex; justify-content: flex-end">
            <v-btn
              v-if="found !== null"
              class="
                btn btn-sm btn-danger
                font-weight-bold
                py-2
                px-3 px-xxl-5
                my-1
              "
              fab
              small
              @click="deleteSavedSearch()"
              :disabled="found == null ? true : false"
            >
              <v-icon dark>mdi-minus</v-icon>
            </v-btn>
          </div>
          <div class="p-4 search_cv_ch" v-if="canSearch">
            <div class="row">
              <div class="col-md-4">
                <v-combobox
                  v-model="desired_salary"
                  :items="desiredSalaryOptions"
                  label="Expected salary "
                  multiple
                  size="sm"
                  class="mt-3"
                  chips
                >
                  <template v-slot:selection="data">
                    <v-chip
                      :key="JSON.stringify(data.item)"
                      v-bind="data.attrs"
                      :input-value="data.selected"
                      :disabled="data.disabled"
                      @click:close="data.parent.selectItem(data.item)"
                    >
                      {{ data.item }}
                    </v-chip>
                  </template>
                </v-combobox>
              </div>
              <div class="col-md-4">
                <v-combobox
                  v-model="job_function"
                  :items="jobFunctionsOptions"
                  label="Job Function "
                  multiple
                  size="sm"
                  class="mt-3"
                  chips
                >
                  <template v-slot:selection="data">
                    <v-chip
                      :key="JSON.stringify(data.item)"
                      v-bind="data.attrs"
                      :input-value="data.selected"
                      :disabled="data.disabled"
                      @click:close="data.parent.selectItem(data.item)"
                    >
                      {{ data.item }}
                    </v-chip>
                  </template>
                </v-combobox>
              </div>
              <div class="col-md-4">
                <v-combobox
                  v-model="city"
                  :items="cityOptions"
                  label="Job Location "
                  multiple
                  size="sm"
                  class="mt-3"
                  chips
                >
                  <template v-slot:selection="data">
                    <v-chip
                      :key="JSON.stringify(data.item)"
                      v-bind="data.attrs"
                      :input-value="data.selected"
                      :disabled="data.disabled"
                      @click:close="data.parent.selectItem(data.item)"
                    >
                      {{ data.item }}
                    </v-chip>
                  </template>
                </v-combobox>
              </div>
            </div>
            <div class="row">
              <div class="col-md-4">
                <v-combobox
                  v-model="job_shift"
                  :items="jobShiftOptions"
                  label="Job Type"
                  multiple
                  size="sm"
                  class="mt-3"
                  chips
                >
                  <template v-slot:selection="data">
                    <v-chip
                      :key="JSON.stringify(data.item)"
                      v-bind="data.attrs"
                      :input-value="data.selected"
                      :disabled="data.disabled"
                      @click:close="data.parent.selectItem(data.item)"
                    >
                      {{ data.item }}
                    </v-chip>
                  </template>
                </v-combobox>
              </div>
              <div class="col-md-4">
                <v-combobox
                  v-model="career_level"
                  :items="careerLevelOptions"
                  label="Career Level"
                  multiple
                  size="sm"
                  class="mt-3"
                  chips
                >
                  <template v-slot:selection="data">
                    <v-chip
                      :key="JSON.stringify(data.item)"
                      v-bind="data.attrs"
                      :input-value="data.selected"
                      :disabled="data.disabled"
                      @click:close="data.parent.selectItem(data.item)"
                    >
                      {{ data.item }}
                    </v-chip>
                  </template>
                </v-combobox>
              </div>
              <div class="col-md-4">
                <v-combobox
                  v-model="skill"
                  :items="skillsOptions"
                  label="Job Skills"
                  multiple
                  size="sm"
                  class="mt-3"
                  chips
                >
                  <template v-slot:selection="data">
                    <v-chip
                      :key="JSON.stringify(data.item)"
                      v-bind="data.attrs"
                      :input-value="data.selected"
                      :disabled="data.disabled"
                      @click:close="data.parent.selectItem(data.item)"
                    >
                      {{ data.item }}
                    </v-chip>
                  </template>
                </v-combobox>
              </div>
            </div>

            <v-card-actions class="p-0">
              <v-btn
                :disabled="submitting"
                class="custom-submit-button btn_submit__ch"
                @click="search"
                ><b-spinner small type="grow" v-if="submitting"></b-spinner>
                <span>Search</span>
              </v-btn>

              <!-- <div
                style="margin-left: 6%"
                class="col-md-3"
                v-if="
                  city !== null ||
                  desired_salary !== null ||
                  job_function !== null ||
                  job_shift !== null ||
                  career_level !== null ||
                  skills
                "
              >
                <v-text-field
                  v-model="search_title"
                  label="Search title"
                  required
                ></v-text-field>

                <v-btn
                  class="
                    btn btn-sm btn-info
                    font-weight-bold
                    py-2
                    px-3 px-xxl-5
                    my-1
                  "
                  small
                  style="float: right"
                  :disabled="search_title !== '' ? false : true"
                  @click="saveSearch"
                >
                  Save search
                </v-btn>
              </div> -->
            </v-card-actions>
          </div>

          <div class="table-responsive">
            <table
              class="
                table
                table-head-custom
                table-vertical-center
                table-head-bg
                table-borderless
              "
            >
              <thead>
                <tr class="text-left rounded-0">
                  <th style="min-width: 250px" class="pl-4 rounded-0">
                    <span class="text-dark-75 rounded-0">Details</span>
                  </th>
                  <th style="min-width: 100px" class="rounded-0 p-0">
                    Profession
                  </th>
                  <th style="min-width: 100px" class="rounded-0 p-0">
                    Desired Salary
                  </th>
                  <!-- <th style="min-width: 101px" class="rounded-0 p-0">
                    LinkedIn
                  </th> -->
                  <th
                    style="min-width: 202px"
                    class="rounded-0 p-0 text-center"
                  >
                    Action
                  </th>
                </tr>
              </thead>
              <p></p>
              <tbody class="cv_search_P_ch">
                <template v-for="(item, i) in data">
                  <tr class="border__ch" v-bind:key="i">
                    <td class="p-0 ch_width_profition1">
                      <div class="d-flex align-items-center px-4 py-1">
                        <!-- {{ item.id }} -->
                        <!-- <div class="symbol symbol-50 symbol-light mr-4">
                          <span class="symbol-label">
                            <img
                              :src="mediaUrl + item.profile_pic"
                              class="h-75 align-self-end"
                              alt=""
                            />
                          </span>
                        </div> -->
                        <div>
                          <span
                            class="m-0 Main_Blue"
                            style="font-size: 14px; font-weight: 600"
                            v-if="item.user.name"
                            >{{ item.user.name }}</span
                          >
                          <span
                            class="m-0 Main_Blue"
                            style="font-size: 14px; font-weight: 600"
                            v-if="!item.user.name"
                            >No name</span
                          >
                          <span
                            v-html="item.details"
                            class="m-0 d-block"
                            style="color: rgb(119, 119, 119); font-size: 11px"
                          ></span>
                          <span
                            v-if="!item.details"
                            class="m-0 d-block"
                            style="color: rgb(119, 119, 119); font-size: 11px"
                            >No Discription</span
                          >
                        </div>
                      </div>
                    </td>
                    <td class="ch_width_profition">
                      <span
                        v-if="item.profession"
                        class="Main_Blue"
                        style="font-size: 14px"
                        >{{ item.profession }}</span
                      >
                      <span
                        v-if="!item.profession"
                        style="font-size: 14px; color: rgb(119, 119, 119)"
                        >Empaty</span
                      >
                    </td>
                    <td class="ch_width_profition">
                      <span class="Main_Blue" style="font-size: 14px"
                        >{{ item.desired_salary }}
                      </span>
                    </td>
                    <!-- <td>
                      <span
                        class="
                          text-dark-75
                          font-weight-bolder
                          d-block
                          font-size-lg
                        "
                        >{{ item.linkedin }}</span
                      >
                    </td> -->

                    <td class="ch_width_profition">
                      <b-form-select
                        :v-model="item.user_id == id && job_id"
                        :options="jobs"
                        size="sm"
                        class="rounded-0"
                        @change="
                          (e) => {
                            myFunc(item.user_id, e);
                          }
                        "
                      ></b-form-select>
                      <v-btn
                        class="
                          btn btn-sm btn-success
                          font-weight-bold
                          my-1
                          rounded-0
                        "
                        fab
                        small
                        @click="shortList(item.user_id)"
                        :disabled="job_id == null ? true : false"
                      >
                        <v-icon dark>mdi-plus</v-icon>
                      </v-btn>
                    </td>

                    <!-- <td class="pr-0 text-left ch_width_profition">
                    </td> -->
                  </tr>

                  <tr class="border__ch" v-bind:key="i">
                    <td colspan="6" style="padding: 0">
                      <v-expansion-panels>
                        <v-expansion-panel>
                          <v-expansion-panel-header>
                            Profile
                          </v-expansion-panel-header>
                          <v-expansion-panel-content>
                            <div>
                              <div class="row">
                                <div class="col col-md-12 row m-0">
                                  <div class="col col-md-4 row m-0">
                                    <div class="col col-md-12 row m-0">
                                      <div class="col-5 p-0">
                                        <b
                                          class="m-0 Main_Blue"
                                          style="
                                            font-size: 12px;
                                            font-weight: 600;
                                          "
                                          >Name:</b
                                        >
                                      </div>
                                      <div
                                        class="col-7"
                                        style="font-size: 12px"
                                        v-if="item.user.name != null"
                                      >
                                        {{ item.user.name }}
                                      </div>
                                      <div
                                        class="col-7"
                                        style="font-size: 12px"
                                        v-if="!item.user.name == null"
                                      >
                                        No Name
                                      </div>
                                    </div>
                                    <div class="col col-md-12 row m-0">
                                      <div class="col-5 p-0">
                                        <b
                                          class="m-0 Main_Blue"
                                          style="
                                            font-size: 12px;
                                            font-weight: 600;
                                          "
                                          >Email:</b
                                        >
                                      </div>
                                      <div
                                        class="col-7"
                                        style="font-size: 12px"
                                        v-if="item.user.email"
                                      >
                                        {{ item.user.email }}
                                      </div>
                                      <div
                                        class="col-7"
                                        style="font-size: 12px"
                                        v-if="!item.user.email"
                                      >
                                        No Email
                                      </div>
                                    </div>
                                    <div class="col col-md-12 row m-0">
                                      <div class="col-5 p-0">
                                        <b
                                          class="m-0 Main_Blue"
                                          style="
                                            font-size: 12px;
                                            font-weight: 600;
                                          "
                                          >Profession:</b
                                        >
                                      </div>
                                      <div
                                        class="col-7"
                                        style="font-size: 12px"
                                        v-if="item.user.profession"
                                      >
                                        {{ item.user.profession }}
                                      </div>
                                      <div
                                        class="col-7"
                                        style="font-size: 12px"
                                        v-if="!item.user.profession"
                                      >
                                        No add profession
                                      </div>
                                    </div>
                                    <div class="col col-md-12 row m-0">
                                      <div class="col-5 p-0">
                                        <b
                                          class="m-0 Main_Blue"
                                          style="
                                            font-size: 12px;
                                            font-weight: 600;
                                          "
                                          >Desired Salary:</b
                                        >
                                      </div>
                                      <div
                                        class="col-7"
                                        style="font-size: 12px"
                                        v-if="item.user.desired_salary"
                                      >
                                        {{ item.user.desired_salary }}
                                      </div>
                                      <div
                                        class="col-7"
                                        style="font-size: 12px"
                                        v-if="!item.user.desired_salary"
                                      >
                                        No add Desired salary
                                      </div>
                                    </div>
                                    <div class="col col-md-12 row m-0">
                                      <div class="col-5 p-0">
                                        <b
                                          class="m-0 Main_Blue"
                                          style="
                                            font-size: 12px;
                                            font-weight: 600;
                                          "
                                          >LinkedIn:</b
                                        >
                                      </div>
                                      <div
                                        class="col-7"
                                        style="font-size: 12px"
                                        v-if="item.linkedin"
                                      >
                                        {{ item.linkedin }}
                                      </div>
                                      <div
                                        class="col-7"
                                        style="font-size: 12px"
                                        v-if="!item.linkedin"
                                      >
                                        No add linkedin URL
                                      </div>
                                    </div>
                                    <div class="col col-md-12 row m-0">
                                      <div class="col-5 p-0">
                                        <b
                                          class="m-0 Main_Blue"
                                          style="
                                            font-size: 12px;
                                            font-weight: 600;
                                          "
                                          >Website:</b
                                        >
                                      </div>
                                      <div
                                        class="col-7"
                                        style="font-size: 12px"
                                        v-if="item.website"
                                      >
                                        {{ item.website }}
                                      </div>
                                      <div
                                        class="col-7"
                                        style="font-size: 12px"
                                        v-if="!item.website"
                                      >
                                        No add website link
                                      </div>
                                    </div>
                                    <div class="col col-md-12 row m-0">
                                      <div class="col-md-12 mt-4 p-0">
                                        <a
                                          :href="
                                            /job-seeker-employability-profile/ +
                                            item.user_id
                                          "
                                          target="_blank"
                                          rel="noopener noreferrer"
                                        >
                                          <button
                                            type="button"
                                            class="btn btn_lood_ch p-2"
                                          >
                                            View Full Profile
                                          </button>
                                        </a>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="col-md-2 text-right pr-0">
                                    <img
                                      :src="mediaUrl + item.profile_pic"
                                      class="cv_image_by_ch"
                                      width="100"
                                      height="100"
                                    />
                                  </div>
                                  <div class="col col-md-6">
                                    <div class="col col-md-12 p-0 row m-0">
                                      <b
                                        class="m-0 Main_Blue"
                                        style="
                                          font-size: 14px;
                                          font-weight: 600;
                                        "
                                        >Professional summary:</b
                                      >
                                      <span
                                        style="font-size: 13px"
                                        v-html="item.details"
                                      ></span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </v-expansion-panel-content>
                        </v-expansion-panel>
                      </v-expansion-panels>
                    </td>
                  </tr>
                  <tr v-if="data.length > 1" v-bind:key="i">
                    <td colspan="12" style="height: 20px"></td>
                  </tr>
                </template>
                <tr v-if="data.length == 0">
                  <td colspan="6" style="text-align: center">No data found</td>
                </tr>
              </tbody>
            </table>
          </div>
        </v-card>
      </div>
      <div class="col-md-4"></div>
    </div>
    <b-toast id="example-toast" title="BootstrapVue" static no-auto-hide>
      Hello, world! This is a toast message.
    </b-toast>
  </div>
</template>

<script>
import axios from "axios";
import { API_URL, MEDIA_URL } from "@/core/api";
import Swal from "sweetalert2";
import GeneralService from "@/MainServices/GeneralService.js";

export default {
  data() {
    return {
      canSearch: true,
      mediaUrl: MEDIA_URL,
      submitting: false,
      loading: true,
      success: false,
      token: `Bearer ${localStorage.getItem("token")}`,
      valid: true,
      createForm: false,
      updateForm: false,
      nameRules: [
        (v) => !!v || "Name is required",
        (v) => (v && v.length <= 10) || "Name must be less than 10 characters",
      ],
      headers: [
        { text: "Job Title", value: "job_title" },
        { text: "City", value: "location_city" },
        { text: "Salary Min.", value: "salary_min" },
        { text: "Salary Max.", value: "salary_max" },
        { text: "Job Type", value: "job_type" },
        { text: "Job Function", value: "job_function" },
        { text: "Experience", value: "experience" },
        { text: "Education", value: "education" },
        { text: "Gender", value: "gender" },
        { text: "No. of Vacancies", value: "no_of_vacancies" },
        { text: "Expire at", value: "apply_by" },
        { text: "Status", value: "status" },
        { text: "Block", value: "block" },
        { text: "Short List", value: "action" },
      ],
      city: null,
      cityOptions: [],
      desired_salary: null,
      desiredSalaryOptions: [
        "Upto 20,000",
        "20,001 - 40,000",
        "40,001 - 60,000",
        "60,001 - 80,000",
        "80,001 - 100,000",
        "100,001 - 125,000",
        "125,001 - 150,000",
        "150,001 - 175,000",
        "175,001 - 225,000",
        "225,001 - 275,000",
        "275,001 - 300,000",
        "300,000 - 350,000",
        "350,000 +",
      ],
      job_function: null,
      jobFunctionsOptions: [],
      job_shift: null,
      jobShiftOptions: [],
      career_level: null,
      careerLevelOptions: [],
      data: [],
      skill: null,
      skillsOptions: [],
      id: null,
      job_id: null,
      jobs: [],
      search_title: "",
      saved_search_titles: [],
      saved_search_title: "",
      found: null,
    };
  },
  mounted() {
    this.fetchStates();
    this.fetchJobFunctions();
    this.fetchCities();
    this.fetchJobShifts();
    this.fetchCareerLevels();
    this.fetchSkills();
    this.fetchCurrentJobs();
    this.fetchSavedSearches();
  },
  methods: {
    fetchStates() {
      axios.defaults.headers.common["Authorization"] = this.token;
      axios
        .get(`${API_URL}/states`)
        .then((res) => {
          res.data.forEach((element) => {
            this.statesOptions.push({
              value: element.id,
              text: element.state_name,
            });
          });
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    fetchJobFunctions() {
      GeneralService.getJobFunctions()
        .then((_res) => {
          _res.data.forEach((element) => {
            this.jobFunctionsOptions.push(element.job_function_name);
          });
          // console.log("jobFunctionsOptions: ", this.jobFunctionsOptions);
        })
        .catch(() => {
          this.loading = false;
        });
    },
    fetchCities() {
      GeneralService.getCities()
        .then((_res) => {
          _res.data.forEach((element) => {
            this.cityOptions.push(element.city_name);
          });
        })
        .catch(() => {
          this.loading = false;
        });
    },
    fetchJobShifts() {
      GeneralService.getJobShifts()
        .then((_res) => {
          _res.data.forEach((element) => {
            this.jobShiftOptions.push(element.job_shift);
          });
        })
        .catch(() => {
          this.loading = false;
        });
    },
    fetchCareerLevels() {
      GeneralService.getJobCareerLevels()
        .then((_res) => {
          _res.data.forEach((element) => {
            this.careerLevelOptions.push(element.career_level);
          });
        })
        .catch(() => {
          this.loading = false;
        });
    },
    fetchSkills() {
      GeneralService.getSkills()
        .then((_res) => {
          _res.data.forEach((element) => {
            _res.data.forEach((element) => {
              this.skillsOptions.push(element.skill_name);
            });
          });
        })
        .catch(() => {
          this.loading = false;
        });
    },
    search() {
      var payload = {
        city: this.city,
        desired_salary: this.desired_salary,
        job_function: this.job_function,
        job_shift: this.job_shift,
        career_level: this.career_level,
        skills: this.skill,
      };
      axios.defaults.headers.common["Authorization"] = this.token;
      axios.post(`${API_URL}/search-cv`, payload).then((res) => {
        // console.log(res.data);
        this.data = res.data;
      });
    },
    fetchCurrentJobs() {
      axios.defaults.headers.common["Authorization"] = this.token;
      axios
        .get(`${API_URL}/jobs`)
        .then((res) => {
          res.data.forEach((element) => {
            this.jobs.push({
              value: element.id,
              text: element.job_title,
            });
          });
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },

    makeToast(variant = null) {
      this.$bvToast.toast("Toast body content", {
        title: `Variant ${variant || "default"}`,
        variant: variant,
        solid: true,
      });
    },

    myFunc(id, value) {
      this.id = id;
      this.job_id = value;
    },

    shortList(id) {
      axios.defaults.headers.common["Authorization"] = this.token;
      axios.get(`${API_URL}/short-list/${id}/${this.job_id}`).then((res) => {
        // console.log(res.data);
        this.$bvToast.toast(res.data.msg, {
          title: `Message`,
          variant: `${res.data.type}`,
          solid: true,
        });
        this.job_id = null;
        this.id = null;
      });
    },

    saveSearch() {
      var payload = {
        city: this.city,
        desired_salary: this.desired_salary,
        job_function: this.job_function,
        job_shift: this.job_shift,
        career_level: this.career_level,
        skills: this.skill,
      };
      axios.defaults.headers.common["Authorization"] = this.token;
      axios
        .post(`${API_URL}/save-search`, {
          search_title: this.search_title,
          payload: payload,
        })
        .then((res) => {
          this.$bvToast.toast(res.data.msg, {
            title: `Message`,
            variant: `success`,
            solid: true,
          });
          this.fetchSavedSearches();
        });
    },

    fetchSavedSearches() {
      axios.defaults.headers.common["Authorization"] = this.token;
      axios
        .get(`${API_URL}/save-search`)
        .then((res) => {
          this.all_saved_search_titles = res.data;
          res.data.forEach((element) => {
            this.saved_search_titles.push({
              value: element.id,
              text: element.search_title,
            });
          });
          this.saved_search_titles.unshift(
            ...[{ value: null, text: "Select saved searches" }]
          );

          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },

    myFunc1(value) {
      this.found = value;
      var found;
      this.all_saved_search_titles &&
        this.all_saved_search_titles.forEach((element) => {
          if (element.id == value) {
            found = element.search_data;
          }
        });
      // console.log(found);
      this.career_level = found.career_level;
      this.city = found.city;
      this.desired_salary = found.desired_salary;
      this.job_function = found.job_function;
      this.job_shift = found.job_shift;
      this.skills = found.skills;

      this.search();
    },

    reset() {
      this.saved_search_titles = this.job_function = null;
      this.jobFunctionsOptions = [];
      this.job_shift = null;
      this.jobShiftOptions = [];
      this.career_level = null;
      this.careerLevelOptions = [];
      this.data = [];
      this.skill = null;
      this.skillsOptions = [];
      this.id = null;
      this.job_id = null;
      this.jobs = [];
      this.city = null;
      this.search_title = "";
      this.saved_search_titles = [];
      this.saved_search_title = "";
      this.found = null;
    },

    deleteSavedSearch() {
      axios.defaults.headers.common["Authorization"] = this.token;
      axios.delete(`${API_URL}/save-search/${this.found}`).then((res) => {
        this.$bvToast.toast(res.data.msg, {
          title: `Message`,
          variant: `success`,
          solid: true,
        });
        this.reset();

        this.fetchSavedSearches();
      });
    },
  },
};
</script>
